import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide89/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide89/image/img.png"
import slider1 from "@components/pageGuide/guides/guide89/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide89/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide89/image/silder3.png"


const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"    >
      Política de Privacidad.
    </a>
  </span>
)

const data = {
  start: {
    support: "Guía | Logística ",
    title: <h1 className="titles-underlined-ebook ebook-ventas">
    Guía para implementar chatbots en la atención de clientes B2B logístico 
    </h1>,
    description: <p className="text-h2-ebook guide__description">
    Los chatbots permiten mejorar la atención en empresas B2B del sector logístico. Automatizan respuestas y agilizan la comunicación con clientes. Segmenta los flujos de atención según cada necesidad para optimizar tiempos de respuesta. Configura mensajes claros que entreguen información precisa en cada interacción. 
    </p>,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-9.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Importancia de los bots en la industria logística B2B.",
      },
      {
        key: 2,
        text: "Cómo elegir una buena herramienta de bots."
      },
      {
        key: 3,
        text: "Estrategia para diseñar respuestas de bots.",
      },
      {
        key: 4,
        text: "Integración del bot con CRM, ERP y más.",
      },
      {
        key: 5,
        text: "Capacitación de agentes por el uso de bots.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },
  description: {
    cards: [
      {
        text: <p>
         Recuerda que, con esta guía, podrás conocer cómo integrar chatbots en la gestión de consultas y seguimiento de envíos. Asegúrate de definir procesos eficientes y conectar los chatbots con otros sistemas. 
<br /><br />
Por último, accede a más detalles, casos de uso y herramientas recomendadas descargándola. Mejora la experiencia de tus clientes con automatización. 

          </p>,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
